@font-face {
  font-family: "SoletoTK";
  src: url("/assets/fonts/SoletoTK_W_Lt.woff") format('woff');
  font-weight: 400;
}

@font-face {
  font-family: "SoletoTK";
  src: url("/assets/fonts/SoletoTK_W_Md.woff") format('woff');
  font-weight: 700;
}

@font-face {
  font-family: "SoletoTKHeadline";
  src: url("/assets/fonts/SoletoTKHeadline_W_Lt.woff") format('woff');
  font-weight: 400;
}
