@import "app/styles/basics.scss";
@import "app/styles/font-import.scss";
@import "app/styles/essentials.scss";


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $color-anthracite-100;
  text-decoration: underline;
  &:hover, &:visited, &:focus {
    color: $color-anthracite-110;
  }
}
